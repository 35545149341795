import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekGondolo = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekGondolo {
        products: allStrapiProduct(
          filter: { brand: { eq: "Patek Philippe" }, model: { regex: "/Gondolo/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Gondolo Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/gondolo/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe Gondolo Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/filter/model/gondolo/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-gondolo-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Gondolo Watches Header"
                aria-label="Used Patek Philippe Gondolo Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE GONDOLO
              WATCHES AT GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE GONDOLO WATCHES WITH
              GRAY AND SONS JEWELERS
            </h2>
            <p>
              We are the best real watch and jewelry store located in Surfside, Miami Beach,
              Florida. We're across from Bal Harbour Shops. Gray and Sons is known for our in
              person, real watch and jewelry experts on-site, open six days a week, and that we're
              real, not a virtual store. Our customers say we are the #1 Miami used watch buyers and
              sellers who've proudly assisted customers for the past 42 years. We're sellers and
              buyers of pre-owned, used and new Patek Philippe Gondolo watches in Miami, Miami
              Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles ,
              Aventura, Fort Lauderdale and many more areas.
              <br />
              <br />
              Come to Gray and Sons for buying, selling and repairing your Patek Philippe Gondolo
              watches. We have the best selection of used and pre-owned Patek Philippe Gondolo
              Watches. Look on our website and free catelog for all of our Patek Philippe watches.
              Interested in talking to a live Patek Philippe Watch expert and decision maker? Rich
              and Vika are ready to work with you. Click <a href="/chat-with-rich">here</a> to chat
              with a real person, now. We offer certified authentic and certfied pre-owned watches.
              <br />
              <br />
              <b>About Patek Philippe Gondolo Watches</b>
              <br />
              <br />
              The Patek Philippe Gondolo watch collection is home to the brand’s Art Deco-inspired
              shaped watches, such as tonneau (a.k.a. barrel), cushion, and rectangular, for men and
              women. While the collection was officially launched in the 1990s, the name is derived
              from a group of passionate Patek Philippe enthusiasts that formed in the early-1800s
              in Rio de Janeiro, Brazil. These Patek collectors dubbed themselves the Gondolo Gang.
              <br />
              <br />
              Patek Philippe Gondolo watches are perfect for those who appreciate fine watches but
              want something different from traditional round timepieces. Gray and Sons in Surfside,
              Miami Beach, Florida has the best selection of Patek Philippe watches. View Gray and
              Son's Patek Philippe watch selection on thier website
              <a href="/"> www.grayandsons.com </a>.
              <br />
              <br />
              <b>Buying Pre-Owned Patek Philippe Gondolo Watches</b>
              <br />
              <br />
              There is plenty of variety within secondhand Patek Philippe Gondolo watches, including
              various different sizes, styles, and complications. From simpler time-only models to
              complicated variants such as annual calendars, the Gondolo collection offers a broad
              assortment of men’s and women’s watches. If you’re in the market to buy a certified
              pre-owned Patek Gondolo watch, then look no further than Gray &amp; Sons. . Gray &amp;
              Sons has a large in-stock inventory of secondhand Patek Philippe Gondolo timepieces —
              and as an independent Patek Philippe watch dealer, we have both price flexibility and
              inventory control. Make a deal today with one of our decision-makers today to purchase
              the preowned Patek Gondolo watch of your dreams.
              <br />
              <br />
              On the other hand, if you’ve recently thought to yourself, I want to sell my Patek
              Philippe Gondolo watch for cash, reach out to Gray &amp; Sons or visit
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a>
              to get the best cash offer available in the market. Popular Pre-Owned Patek Philippe
              Gondolo Refrence Numbers are: Ref. 4824, Ref. 4825, Ref. 4972, Ref. 7041, Ref. 7042,
              Ref. 7099, and Ref. 5010.
              <br />
              <br />
              <b>Servicing Pre-Owned Patek Philippe World Time Watches</b>
              <br />
              <br />
              Gray &amp; Sons boasts a full-service Patek Philippe Gondolo watch repair shop on-site
              with a team of highly-trained skilled watchmakers. Due to their detailed workmanship
              and Swiss-made movements, the servicing and repair of second hand Patek Gondolo
              watches should only be done by expert watchmakers. Whether for a complicated repair
              job, regular maintenance, or battery replacement, our customers agree that Gray &amp;
              Son’s independent Patek Philippe repair center is the best in Miami.
              <br />
              <br />
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/filter/model/gondolo/">
                <button>SHOP PATEK PHILIPPE GONDOLO WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekGondolo
